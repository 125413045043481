import './Constructor.scss';
import '@xyflow/react/dist/style.css';

import {
  addEdge,
  Background,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  MarkerType,
  OnEdgesDelete,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react';
import { DnDProvider, useDnD } from 'context/DnDContext';
import { FC, useCallback, useEffect, useState } from 'react';
import { deepClone, uuid } from 'utils/data';
import { mapNodeDataByType } from 'utils/reactFlow';
import { Button } from 'reactstrap';
import { InputParams, SettingConstructor, Tools } from './components';
import edgeTypes from './components/Edge';
import CustomConnectionLine from './components/Edge/CustomConnectionLine';
import nodeTypes from './components/Node';
import { ConstructorProps, ConstructorReactFlowProps } from './types';
import { useSaveScheme } from 'hooks/useSaveScheme';
import PowerCalculatorService from 'store/powerCalculator/powerCalculator.service';
import { AuxiliaryLines, BranchData, ISchemeData } from 'types/powerCalculator';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setInputParams, setDefInputParams, setVmCost } from 'store/powerCalculator/powerCalculator.actions';
import { DownloadButton } from './components/DownloadButton/DownloadButton';
import { getPowerCalculatorStatusSubmitting } from 'store/powerCalculator/powerCalculator.selectors';
import { useUpdateBranchEdges } from 'hooks/useUpdateBranchEdges';

const connectionLineStyle = {
  strokeWidth: 3,
  stroke: 'black',
};

const defaultEdgeOptions = {
  style: { strokeWidth: 3, stroke: 'black' },
  type: 'floating',
  markerEnd: {
    type: MarkerType.ArrowClosed,
    color: 'black',
  },
};



const ALIGNMENT_THRESHOLD = 10; // for auxiliary line
const ConstructorReactFlow: FC<ConstructorReactFlowProps> = ({ nodes: defNodes, edges: defEdges }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState<ISchemeData['scheme']['nodes'][0]>(defNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState<ISchemeData['scheme']['edges'][0]>(defEdges);
  const [auxiliaryLines, setAuxiliaryLines] = useState<AuxiliaryLines[]>([]); // State to hold guiding lines
  const isSubmitting = useAppSelector(getPowerCalculatorStatusSubmitting);
  const onUpdateBranch = useUpdateBranchEdges()

  const { screenToFlowPosition } = useReactFlow();
  const onSaveScheme = useSaveScheme();
  const [type] = useDnD();

  const onConnect = useCallback(
    (params) => {
      setEdges(addEdge(params, edges))
      setTimeout(() => {
        const sourceNode = nodes.find(node => node.id === params.source);
        if (sourceNode && sourceNode.type === 'branch') {
          onUpdateBranch(sourceNode.id, sourceNode.data as unknown as BranchData)
        }
      })

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [edges]
  );

  const handleSave = () => {
    onSaveScheme();
  };

  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodes, edges]
  );

  const onEdgesDelete: OnEdgesDelete = useCallback(
    (deleted) => {
      setNodes((nodesState) => {
        return nodesState.map((node) => {
          const nodeClone = deepClone(node);

          const deletedEdges = deleted.filter(
            (edge) => edge.target === node.id
          );

          deletedEdges.forEach((edge) => {
            if (nodeClone.data?.incoming_load?.[edge.source]) {
              delete nodeClone.data.incoming_load[edge.source];
            }

          });

          return nodeClone;
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodes, edges]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      // check if the dropped element is valid
      if (!type) {
        return;
      }
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: uuid(),
        type,
        position,
        data: mapNodeDataByType[type],
      };

      //@ts-ignore
      setNodes((nds) => nds.concat(newNode));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [screenToFlowPosition, type]
  );

  const handleNodeDrag = useCallback((event, node) => {
    const lines: AuxiliaryLines[] = [];

    nodes.forEach((otherNode) => {
      if (otherNode.id !== node.id) {
        // Check for horizontal and vertical alignment within the threshold

        if (Math.abs(node.position.x - otherNode.position.x) < ALIGNMENT_THRESHOLD) {
          lines.push({ x: event.clientX, y1: 0, y2: window.innerHeight, orientation: 'vertical' });
        }
        if (Math.abs(node.position.y - otherNode.position.y) < ALIGNMENT_THRESHOLD) {
          lines.push({ y: event.layerY, x1: 0, x2: window.innerWidth, orientation: 'horizontal' });
        }
      }
    });

    setAuxiliaryLines(lines);
  }, [nodes]);

  const handleNodeDragStop = () => {
    setAuxiliaryLines([]); // Clear guidelines after dragging stops
  };

  return (
    <div className='constructor'>
      <div className='reactflow-wrapper'>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          snapToGrid={true}    // Enable snapping to grid

          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgesDelete={onEdgesDelete}
          onNodesDelete={onNodesDelete}
          onConnect={onConnect}
          fitView
          attributionPosition='top-right'
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          className='constructor'
          defaultEdgeOptions={defaultEdgeOptions}
          onDrop={onDrop}
          onDragOver={onDragOver}
          connectionLineComponent={CustomConnectionLine}
          connectionLineStyle={connectionLineStyle}
          onNodeDrag={handleNodeDrag}
          onNodeDragStop={handleNodeDragStop}
          proOptions={{
            account: 'paid-pro',
            hideAttribution: true,
          }}
          minZoom={0.1}
          maxZoom={2}
        >

          <Background gap={12} size={1} />
          {auxiliaryLines.map((line, index) => (
            <div
              key={index}
              className='auxiliaryLines'
              style={{
                position: 'absolute',
                backgroundColor: 'rgba(0, 0, 255, 0.5)',
                ...(line.orientation === 'vertical' ? { left: line.x, top: 0, width: 2, height: '100%' } : {}),
                ...(line.orientation === 'horizontal' ? { top: line.y, left: 0, height: 2, width: '100%' } : {}),
              }}
            />
          ))}
        </ReactFlow>

      </div>
      <InputParams >
        <Tools />
        <hr />
        <div className='constructor__buttons'>
          <Button
            size='sm'
            onClick={handleSave}
            color='primary'
            disabled={isSubmitting}
          >
            Сохранить
          </Button>
          <DownloadButton />
        </div>


      </InputParams>
      <SettingConstructor />



    </div>
  );
};

export const Constructor: FC<ConstructorProps> = () => {
  const { id } = useParams();

  const [initScheme, setInitScheme] = useState<ISchemeData['scheme'] | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      PowerCalculatorService.getScheme(id).then((data) => {
        setInitScheme(data);

        dispatch(setInputParams(data?.inputParams || []));
        dispatch(setDefInputParams(data?.defInputParams || []));
        dispatch(setVmCost(data?.vmCost || {}));
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  if (!initScheme) {
    return null
  }

  return (
    <ReactFlowProvider>
      <DnDProvider>
        <ConstructorReactFlow nodes={initScheme?.nodes || []} edges={initScheme?.edges || []} />
      </DnDProvider>
    </ReactFlowProvider>
  );
};
