export const SERVER_URL = '/api'

export const ENDPOINTS = {
  AUTH: 'auth',
  USER: 'users',
  LICENSES: 'licenses',
  CERTIFICATES: 'certificates',
  SCHEMES: 'schemes',
  CALCULATIONS_HISTORY: 'calculationsHistory',
}
