import './PowerCalculatorForm.scss';

import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { Alert, Button, Card, CardBody, Form, FormGroup, Input } from 'reactstrap';

import { setCalculateResult } from 'store/powerCalculator/powerCalculator.actions';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import PowerCalculatorService from 'store/powerCalculator/powerCalculator.service';
import { FormDataForCalculation } from 'types/powerCalculator';
import { createPortal } from 'react-dom';


interface IError {
  type: string;
  message: string;
}

interface Props {
  handelCalculate: (data: FormDataForCalculation) => Promise<unknown>
}

const PowerCalculatorForm: FC<Props> = ({ handelCalculate }) => {
  const [error] = useState<null | IError>(null);
  const dispatch = useAppDispatch()
  const schemes = useAppSelector((state) => state.powerCalculator.schemes);
  const calculationsHistory = useAppSelector((state) => state.powerCalculator.calculationsHistory);
  const reportControls = document.getElementById('report_controls')

  const {
    setFieldValue,
    setValues,
    handleChange,
    handleSubmit,
    values,
  } = useFormik<FormDataForCalculation | {}>({
    initialValues: {
      type: 'view'
    },

    async onSubmit(values: FormDataForCalculation | {}) {
      if ('schemeId' in values) {
        await handelCalculate(values)
      }
    },
  });

  const handleSelectScheme: React.ChangeEventHandler<HTMLInputElement> = (event) => {

    const selectedSchemeId = event.target.value;
    const selectedScheme = schemes.find(scheme => `${scheme.id}` === selectedSchemeId);

    const inputParams = selectedScheme?.scheme.inputParams?.reduce((params, parameter) => ({ ...params, [parameter.value]: 0 }), {}) || {};
    if (selectedScheme) {
      setValues({ schemeId: selectedSchemeId, inputParams, calculationHistoryId: -1 })
    } else {
      setValues({})
    }
  }

  const handleSelectCalculationHistory: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    const calculationHistoryId = event.target.value;
    const selectedScheme = calculationsHistory.find(scheme => `${scheme.id}` === calculationHistoryId);

    if (selectedScheme) {
      const { data: response } = await PowerCalculatorService.getCalculationHistory(calculationHistoryId);

      if (response) {
        const { calculateResult, inputParams } = response.result
        dispatch(setCalculateResult(calculateResult))
        setValues({ schemeId: response.schemeId, inputParams, calculationHistoryId })
      }
    }
    else {
      setValues({ ...values, calculationHistoryId: -1 })
    }
  }


  const handleSubmitByType = (type: FormDataForCalculation['type']) => async () => {
    setFieldValue('type', type)
    handleSubmit()
  }

  const handleRemoveResult = async () => {
    if ('schemeId' in values) {
      await handelCalculate({ ...values, type: 'removeResult' })
    }
    setValues({ schemeId: -1 })
  }

  const inputParams = 'schemeId' in values ? schemes.find(scheme => `${scheme.id}` === values?.schemeId)?.scheme?.inputParams || [] : [];
  const isSelectedScheme = 'schemeId' in values && +values.schemeId > 0;
  const isSelectedCalculationHistory = 'calculationHistoryId' in values && +values.calculationHistoryId > 0;

  return (
    <Card className="border-0 flex-grow-1 mt-3">
      <CardBody className="d-flex flex-column">
        <div className="power-calculator-form ">

          <FormGroup className="ms-2 me-2 mb-2">
            <div className="input__title">Выбор из истории расчетов:</div>
            {/* @ts-ignore */}
            <Input name="role" type="select" onChange={handleSelectCalculationHistory} value={values?.calculationHistoryId}>
              <option value={undefined}>
                Выбрать расчет
              </option>
              {calculationsHistory.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup className="ms-2 me-2 mb-2">
            <div className="input__title">Выбор проекта:</div>
            {/* @ts-ignore */}
            <Input name="role" type="select" onChange={handleSelectScheme} value={values?.schemeId}>
              <option value={undefined}>
                Выбрать схему
              </option>
              {schemes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.description}
                </option>
              ))}
            </Input>
          </FormGroup>

          {isSelectedScheme && <>
            <Form id='power-calculator' onSubmit={handleSubmit} className='power-calculator-form__input-params'>
              <div>
                {inputParams.map((parameter) => (
                  <FormGroup key={parameter.value}>
                    <div className="input__title">{parameter.label}</div>
                    <Input
                      required
                      name={`inputParams.${[parameter.value]}`}
                      type="number"
                      onChange={handleChange}
                      invalid={!values.inputParams[parameter.value]}
                      value={values.inputParams[parameter.value]}
                      step={1}
                    />
                  </FormGroup>
                ))}
              </div>

              {!!error && error.type === 'server' && (
                <Alert color="danger">{error.message}</Alert>
              )}
            </Form>
          </>
          }
          <FormGroup className="text-end mt-4 mr-4 me-2">
            <Button
              form='power-calculator'
              color="primary"
              type="button"
              disabled={!isSelectedScheme}
              onClick={handleSubmitByType('view')}
            >
              Рассчитать
            </Button>
          </FormGroup>
          {
            <FormGroup className="text-end mt-4 mr-4">
              {isSelectedCalculationHistory && <Button
                form='power-calculator'
                color="secondary"
                type="button"
                disabled={!isSelectedScheme}
                className="me-2"
                onClick={handleRemoveResult}
              >
                Удалить расчет
              </Button>}

              <Button
                form='power-calculator'
                color="secondary"
                type="button"
                disabled={!isSelectedScheme}
                className="me-2"
                onClick={handleSubmitByType('saveResult')}
              >
                Сохранить расчет
              </Button>

              {
                reportControls &&
                createPortal(
                  <>
                    <Button
                      form='power-calculator'
                      color="primary"
                      type="button"
                      disabled={!isSelectedScheme}
                      className="me-2"
                      onClick={handleSubmitByType('downloadManifest')}
                    >
                      Скачать манифест
                    </Button>
                    <Button
                      form='power-calculator'
                      color="primary"
                      type="button"
                      disabled={!isSelectedScheme}
                      onClick={handleSubmitByType('downloadResult')}
                    >
                      Скачать расчет
                    </Button>
                  </>,
                  reportControls
                )
              }
            </FormGroup>
          }
        </div>
      </CardBody>
    </Card>
  );
};

export default PowerCalculatorForm;

