import { AxiosResponse } from 'axios'
import { ENDPOINTS } from 'constants/api_endpoints'
import { CalculateResponse, FormDataForCalculation, ICalculationHistoryData, ISchemeData, PowerCalculatorFormAction } from 'types/powerCalculator'
import axiosApi from 'utils/api_helper'
import { getLinkForDownloadCalculateManifest, getLinkForDownloadCalculateResult } from 'utils/data'


export default class PowerCalculatorService {
  static async getAllSchemes() {
    const response: AxiosResponse<ISchemeData[]> = await axiosApi.get(`${ENDPOINTS.SCHEMES}`)

    return response
  }

  static async getScheme(schemeId: string) {
    const response: AxiosResponse<ISchemeData> = await axiosApi.get(`${ENDPOINTS.SCHEMES}/${schemeId}`)

    return response?.data.scheme
  }

  static async createScheme(schemeDescription: string) {
    const data: Omit<ISchemeData, 'id' | 'createdAt'> = {
      description: schemeDescription,
      scheme: {
        nodes: [],
        edges: [],
        inputParams: [],
        defInputParams: [{ "rate": 0, "label": "максимальная  загруженность по RAM", "value": "maxDownloadRam" }, { "rate": 0, "label": "максимальная загруженность по CPU", "value": "maxDownloadCpu" }],
        vmCost: {
          cpu: 0,
          ram: 0,
          hdd: 0,
          gpu: 0
        }
      }
    }

    const response: AxiosResponse<ISchemeData> = await axiosApi.post(ENDPOINTS.SCHEMES, data)
    return response
  }


  static async updateScheme(schemeId: string, scheme: Omit<ISchemeData['scheme'], 'id' | 'createdAt' | 'description'>) {
    const response: AxiosResponse<ISchemeData> = await axiosApi.put(`${ENDPOINTS.SCHEMES}/${schemeId}`, scheme)
    return response
  }

  static async deleteScheme(schemeId: string) {
    const response: AxiosResponse<ISchemeData> = await axiosApi.delete(`${ENDPOINTS.SCHEMES}/${schemeId}`)
    return response
  }

  static async calculateScheme({ type, ...data }: FormDataForCalculation & { type: 'view'}) {
    if (type === 'view') {
      const response: AxiosResponse<CalculateResponse> = await axiosApi.post(`${ENDPOINTS.SCHEMES}/calculate/${data.schemeId}`, data)
      return response.data || null
    }
  }

  static async downloadManifest(schemeDataForCalculation:FormDataForCalculation & { type: 'downloadManifest'}) {
    //@ts-ignore
    const response = await axiosApi.get(getLinkForDownloadCalculateManifest(schemeDataForCalculation))

    const jsonBlob = new Blob([JSON.stringify(response.data, null, 2)], { type: 'application/json' });
    const url = window.URL.createObjectURL(jsonBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'distributionMicroservices.json'); // File name to save
    document.body.appendChild(link);
    link.click();
    link.remove();

    return
  }

  static async downloadResult(schemeDataForCalculation: FormDataForCalculation & { type: 'downloadResult'}) {
    //@ts-ignore
    const response = await axiosApi.get(getLinkForDownloadCalculateResult(schemeDataForCalculation), {
      responseType: 'blob', 
    })

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = 'calculationResult.xlsx';
    link.click();

    URL.revokeObjectURL(url);
    return
  }


  //------
  static async getAllCalculationsHistory() {
    const response: AxiosResponse<ICalculationHistoryData[]> = await axiosApi.get(`${ENDPOINTS.CALCULATIONS_HISTORY}`)

    return response
  }

  static async createCalculationHistory(calculationResult: Omit<ICalculationHistoryData, 'id'>) {
    const response: AxiosResponse<ICalculationHistoryData> = await axiosApi.post(`${ENDPOINTS.CALCULATIONS_HISTORY}`, calculationResult)

    return response
  }

  static async getCalculationHistory(calculationHistoryId: string) {
    const response: AxiosResponse<ICalculationHistoryData> = await axiosApi.get(`${ENDPOINTS.CALCULATIONS_HISTORY}/${calculationHistoryId}`)

    return response
  }

  static async deleteCalculationHistory(calculationHistoryId: string) {
    const response: AxiosResponse<ICalculationHistoryData> = await axiosApi.delete(`${ENDPOINTS.CALCULATIONS_HISTORY}/${calculationHistoryId}`)

    return response
  }
}
