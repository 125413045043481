import { createAsyncThunk } from '@reduxjs/toolkit'
import PowerCalculatorService from './powerCalculator.service'
import { powerCalculatorSlice } from './powerCalculator.reducer';
import { FormDataForCalculation } from 'types/powerCalculator';


export const getAllSchemes = createAsyncThunk('schemes', async () => {
  const response = await PowerCalculatorService.getAllSchemes();

  return response.data
})

export const getAllCalculationsHistory = createAsyncThunk('calculationsHistory', async () => {
  const response = await PowerCalculatorService.getAllCalculationsHistory();

  return response.data
})


export const getCalculateResult= createAsyncThunk('calculateResult', async ( params: FormDataForCalculation & { type: 'view'}) => {
  const response = await PowerCalculatorService.calculateScheme(params);

  return response
})

export const { setSelectedNode, setInputParams, setDefInputParams , setStatusSubmitting, setVmCost, setCalculateResult} = powerCalculatorSlice.actions;
