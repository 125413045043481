import { InfoLabel } from 'components/InfoLabel';
import './PowerCalculatorResultTable.scss';
import { FC } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Card, CardBody } from 'reactstrap';
import { CalculateResponse } from 'types/powerCalculator';
import { getFormattedNumber } from 'utils/data';


const descriptionFormulaCPU = 'Суммарная нагрузка по CPU по всем сервисам с учетом дополнительных ресурсов на новую VM, пересчитанная в физические ядра с учетом потоков'
const descriptionFormulaOther = (indicatorName: string) => `Суммарная нагрузка ${indicatorName} по всем сервисам с учетом дополнительных ресурсов на новую VM`

const serviceResultsColumns = [{
  dataField: 'name',
  text: 'Имя'
}, {
  dataField: 'cpu',
  text: 'cpu (%)'
}, {
  dataField: 'ram',
  text: 'ram (MB)'
},
{
  dataField: 'hdd',
  text: 'hdd (MB)'
},
{
  dataField: 'gpu',
  text: 'gpu (MB)'
},
{
  dataField: 'numberReplicas',
  text: 'Кол-во реплик'
}];


const PowerCalculatorResultTable: FC<{ data: CalculateResponse, children: React.ReactNode }> = ({ data, children }) => {
  const vmResultsColumns = [{
    dataField: 'name',
    text: 'Имя'
  }, {
    dataField: 'cpu',
    text: 'cpu (ядра)',
    formatter: (cpu: number) => {
      return cpu ? `${cpu} + ${data.vmCost.cpu} = ${Math.ceil((cpu + data.vmCost.cpu) / 2 * 0.01)} (ядра)` : '-';
    },
    headerFormatter: () => {
      return <div className='power-calculator-result-table__header'> 
                <InfoLabel htmlFor='cpu' children={null} description={descriptionFormulaCPU} /> cpu (ядра)
              </div>
    }
  }, {
    dataField: 'ram',
    text: 'ram (MB)',
    formatter: (ram: number) => {
      return ram ? `${getFormattedNumber(ram)} + ${getFormattedNumber(data.vmCost.ram)}` : '-';
    },
    headerFormatter: () => {
      return <div className='power-calculator-result-table__header'> 
                <InfoLabel htmlFor='ram' children={null} description={descriptionFormulaOther('RAM')} /> ram (MB) 
              </div>
    }
  }, {
    dataField: 'hdd',
    text: 'hdd (MB)',
    formatter: (hdd: number) => {
      return hdd ? `${getFormattedNumber(hdd)} + ${getFormattedNumber(data.vmCost.hdd)}` : '-';
    },
    headerFormatter: () => {
      return <div className='power-calculator-result-table__header'> 
              <InfoLabel htmlFor='ram' children={null} description={descriptionFormulaOther('HDD')} /> hdd (MB)
            </div>
    }

  }, {
    dataField: 'gpu',
    text: 'gpu (MB)',
    formatter: (gpu: number) => {
      return gpu ? `${getFormattedNumber(gpu)} + ${getFormattedNumber(data.vmCost.gpu)}` : '-';
    },
    headerFormatter: () => {
      return <div className='power-calculator-result-table__header'> 
              <InfoLabel htmlFor='ram' children={null} description={descriptionFormulaOther('GPU')} /> gpu (MB)
            </div>
    }
  }];


  const expandRow = {
    renderer: (row: CalculateResponse['vmResults'][0]) => (
      <ul>{
        row.services.map(service =>
          <li key={service.serviceId}>{service.serviceName}{` (cpu:${service.percentDownloadCpu}% | ram:${service.percentDownloadRam} | hdd:${service.percentDownloadRam} | gpu:${service.percentDownloadRam})`}</li>)
      }</ul>

    ),
    showExpandColumn: true,
    expandByColumnOnly: true
  };

  return <Card className="border-0 flex-grow-1 mt-3">
    <CardBody className="d-flex flex-column">
      <div className="power-calculator-result-table">

        {children}
        <div className="power-calculator-result-table__content">

          <span>Распределение по виртуальным машинам</span>

          <BootstrapTable
            keyField='name'
            data={data.vmResults}
            columns={vmResultsColumns}
            expandRow={expandRow}
          />

          <span>Итоговая нагрузка по микросервисам </span>
          <BootstrapTable
            keyField='name'
            data={data.serviceResults}
            columns={serviceResultsColumns}
          />
        </div>
      </div>
    </CardBody>
  </Card>

}

export default PowerCalculatorResultTable;
