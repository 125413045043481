import './Tooltip.scss';

import { FC } from 'react';
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';

import { TooltipProps } from './types';

export const Tooltip: FC<TooltipProps> = ({
  content,
  placement,
  children,
  trigger
}) => {
  return (
    <OverlayTrigger
      placement={placement ? placement : 'bottom'}
      trigger={trigger}
      overlay={
        <BootstrapTooltip className="tooltip">{content}</BootstrapTooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};
