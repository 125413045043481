import { FormDataForCalculation } from 'types/powerCalculator';
import { useAppDispatch } from './redux';
import { getAllCalculationsHistory, getCalculateResult } from 'store/powerCalculator/powerCalculator.actions';
import PowerCalculatorService from 'store/powerCalculator/powerCalculator.service';



function isDataForView(data: FormDataForCalculation): data is FormDataForCalculation & { type: 'view' } {
    return data.type === "view";
}

function isDataForDownloadResult(data: FormDataForCalculation): data is FormDataForCalculation & { type: 'downloadResult' } {
    return data.type === "downloadResult";
}

function isDataForDownloadManifest(data: FormDataForCalculation): data is FormDataForCalculation & { type: 'downloadManifest' } {
    return data.type === "downloadManifest";
}

function isDataForRemoveResult(data: FormDataForCalculation): data is FormDataForCalculation & { type: 'removeResult' } {
    return data.type === "removeResult";
}

export const useHandleCalculationResult = () => {
    const dispatch = useAppDispatch()

    return async (params: FormDataForCalculation) => {
        if (isDataForView(params)) {
            dispatch(getCalculateResult(params))
            return
        }

        if (isDataForDownloadManifest(params)) {
            PowerCalculatorService.downloadManifest(params)
            return
        }

        if (isDataForDownloadResult(params)) {
            PowerCalculatorService.downloadResult(params)
            return
        }
        if (isDataForRemoveResult(params)) {
            await  PowerCalculatorService.deleteCalculationHistory(params.calculationHistoryId)
            dispatch(getAllCalculationsHistory())
            return
        }
    }

}
