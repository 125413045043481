import './InfoLabel.scss';
import { FC } from 'react';
import { InfoLabelProps } from './types';
import { Tooltip } from 'components/Tooltip';


export const InfoLabel: FC<InfoLabelProps> = ({
  description,
}) => {
  return (
    <Tooltip content={description || 'Описание отсутствует'} placement='top'>
        <span className="info-label __icon bx bx-help-circle" />
    </Tooltip>
  );
};
