import './PowerCalculator.scss';

import { FormDataForCalculation, PowerCalculatorFormAction } from 'types/powerCalculator';

import PowerCalculatorResultTable from './components/PowerCalculatorResultTable/PowerCalculatorResultTable';
import { Card, CardBody } from 'reactstrap';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import PowerCalculatorForm from './components/PowerCalculatorForm/PowerCalculatorForm';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getAllCalculationsHistory, getAllSchemes } from 'store/powerCalculator/powerCalculator.actions';
import PowerCalculatorService from 'store/powerCalculator/powerCalculator.service';
import { useModal } from 'hooks/useModal';
import PowerCalculatorHistoryName from './components/PowerCalculatorHistoryName/PowerCalculatorHistoryName';
import { useEffect, useRef } from 'react';
import { useHandleCalculationResult } from 'hooks/useHandleCalculationResult';
import PowerCalculatorTotalResult from './components/PowerCalculatorTotalResult/PowerCalculatorTotalResult';


const PowerCalculator = () => {
  const { modal, closeModal, openModal } = useModal<
    PowerCalculatorFormAction,
    string
  >();

  let formData: React.MutableRefObject<FormDataForCalculation | null> = useRef<FormDataForCalculation>(null);
  const handleCalculationResult = useHandleCalculationResult()
  const dispatch = useAppDispatch()
  const calculateResult = useAppSelector((state) => state.powerCalculator.calculateResult);


  useEffect(() => {
    dispatch(getAllSchemes())
    dispatch(getAllCalculationsHistory())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handelCalculate = async (params: FormDataForCalculation) => {
    if (params.type === 'saveResult') {
      formData.current = params
      openModal('saveResult')
      return
    }

    await handleCalculationResult(params)
  }


  const handelSaveResult = async (calculationsHistoryName: string) => {
    if (!formData.current) {
      return
    }

    const { inputParams, schemeId } = formData.current;

    if (inputParams && calculateResult) {
      return await PowerCalculatorService.createCalculationHistory({
        result: { inputParams, calculateResult }, name: calculationsHistoryName,
        schemeId
      }).then(() => {
        dispatch(getAllCalculationsHistory())
      })
    }

  }

  return (
    <>
      <BreadCrumbs secondItem="Калькулятор" title="Калькулятор мощности" />
      <div className="power-calculator">
        <PowerCalculatorForm handelCalculate={handelCalculate} />
        <PowerCalculatorHistoryName isOpen={modal.isOpen === 'saveResult'} close={closeModal}
          create={closeModal} handle={handelSaveResult} />
        {calculateResult ? 
   
        <PowerCalculatorResultTable data={calculateResult} >
          <PowerCalculatorTotalResult data={calculateResult}/>
        </PowerCalculatorResultTable> :
          <Card className="border-0 flex-grow-1 mt-3">
            <CardBody className="d-flex flex-column align-items-center justify-content-center">
              Для расчета мощности необходимо выбрать нужную схему и заполнить параметры
            </CardBody>
          </Card>}
      </div>
    </>
  );
};

export default PowerCalculator;
